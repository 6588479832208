$(".search-form").on('keydown keyup', function () {
    $(".search").css("visibility", "hidden");
    var textNull = $(".filter-maintenance > input").val();
    if (textNull === "") {
        $(".search").css("visibility", "visible");
    }

});

window.myFunction = function() {
    document.getElementById("myDropdown").classList.toggle("show");
};

// Close the dropdown if the user clicks outside of it
window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
        var myDropdown = document.getElementById("myDropdown");
        if (myDropdown && myDropdown.classList.contains('show')) {
            myDropdown.classList.remove('show');
        }
    }
};

$(document).ready(function () {
    $.fn.addTokensToBody = function (token_id, body_id) {
        var $token = $(token_id);
        var $body = $(body_id);
        select_token = $token.val();
        body = $body.val();
        if(select_token)
        {
            $body.val(body + " {{" + select_token + "}} ");
        }
    };
});